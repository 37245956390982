import * as React from "react"
import styled from "styled-components"

const ResumeContainer = styled.div`
  p,
  a {
    padding: 0.1em 0.3em;
    margin: 0 auto;
  }

  h1 {
    padding: 0.1em;
    margin: 0 auto;
  }
`

const StyledTitle = styled.h1`
  color: #16242f;
`

const ResumeSection = ({ children }) => {
  const data = children
  return (
    <ResumeContainer>
      <StyledTitle>resume</StyledTitle>
      {data.map((item, i) => (
        <>
          <p>
            <a href={item.url} target="_blank">
              {item.company}
            </a>{" "}
            &middot; {item.title} &middot; {item.location}
          </p>
        </>
      ))}
    </ResumeContainer>
  )
}

export default ResumeSection
